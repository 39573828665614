import React from "react";
import { Link } from "gatsby";

import logoB from "../../assets/image/logo.png";
import logoW from "../../assets/image/logo.png";

const Logo = ({ className = "", ...rest }) => {
  return (
    <Link to='/' className={`${className}`} {...rest}>
      <img src={logoB} alt='' className='light-version-logo mt-lg-4 mt-md-0 mt-xs-0' style={{ height: 45 }} />
      <img src={logoW} alt='' className='dark-version-logo mt-lg-4 mt-md-0 mt-xs-0' style={{ height: 45 }} />
    </Link>
  );
};

export default Logo;
