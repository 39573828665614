import React, { useState, useEffect, useContext, useRef } from "react";

import styled from "styled-components";
import { Helmet } from "react-helmet";
import AOS from "aos";

import Header from "../Header";
import Footer from "../Footer";

import ModalVideo from '../ModalVideo';

import GlobalContext from "../../context/GlobalContext";

import imgFavicon from "../../assets/favicon.png";

import "../../assets/fonts/fontawesome-5/webfonts/fa-brands-400.ttf";
import "../../assets/fonts/fontawesome-5/webfonts/fa-regular-400.ttf";
import "../../assets/fonts/fontawesome-5/webfonts/fa-solid-900.ttf";

import '../../assets/fonts/typography-font/D-DIN-Bold.woff';
import '../../assets/fonts/typography-font/D-DIN.woff';
import '../../assets/fonts/typography-font/D-DINCondensed.woff';
import '../../assets/fonts/typography-font/D-DIN-Italic.woff';
import '../../assets/fonts/typography-font/D-DINExp-Italic.woff';

import "../../assets/fonts/icon-font/fonts/avasta.ttf";
import "../../assets/fonts/icon-font/css/style.css";

import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/aos/dist/aos.css";

import "../../assets/fonts/icon-font/css/style.css";
import "../../assets/fonts/typography-font/typo.css";
import "../../assets/fonts/fontawesome-5/css/all.css";

import "../../assets/scss/bootstrap.scss";
import "../../assets/scss/main.scss";

const Loader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  z-index: 9999999999;
  opacity: 1;
  visibility: visible;
  transition: all 1s ease-out 0.5s;
  &.inActive {
    opacity: 0;
    visibility: hidden;
  }
`;

const Layout = ({ children, pageContext }) => {
  const gContext = useContext(GlobalContext);

  const [visibleLoader, setVisibleLoader] = useState(true);

  useEffect(() => {
    AOS.init({ once: true });
    setVisibleLoader(false);
  }, []);

  // Navbar style based on scroll
  const eleRef = useRef();

  useEffect(() => {
    window.addEventListener(
      "popstate",
      function(event) {
        // The popstate event is fired each time when the current history entry changes.
        gContext.closeOffCanvas();
      },
      false
    );
    window.addEventListener(
      "pushState",
      function(event) {
        // The pushstate event is fired each time when the current history entry changes.
        gContext.closeOffCanvas();
      },
      false
    );

    return () => {};
  }, [gContext]);

  return (
    <>
      <>
        <Helmet>
          <title>Akofis Engineering</title>
          <link rel="icon" type="image/png" href={imgFavicon} />
          <body data-theme={'dark'} />
        </Helmet>
        <Loader id="loading" className={visibleLoader ? '' : 'inActive'} />
        <div className="site-wrapper overflow-hidden" ref={eleRef}>
          <Header />
          {children}
          <Footer
            className={gContext.theme.footerClassName}
            style={gContext.theme.footerStyle}
          />
        </div>
        {/* <ModalVideo /> */}
      </>
    </>
  );
};

export default Layout;
