import React from "react";

import FooterDefault from "./FooterDefault";

const Footer = ({ className = "", style, ...rest }) => {
  let FooterEl = FooterDefault;
  if (style === "none") {
    return <></>;
  }

  return (
    <>
      <FooterEl className={className} {...rest} />
    </>
  );
};

export default Footer;
